<template>
  <div style="min-height: 450px;">
    <div class="row mb-2">
      <div class="col-xs-12 col-sm-12 col-md-8 col-lg-9 grid-toolbar">
        
        <b-button-group >
          <b-form-datepicker id="example-datepicker" v-model="dateHistory" @input="onchangeDate()"></b-form-datepicker>

          <!-- <b-button
            v-ripple.400="'rgba(113, 102, 240, 0.15)'"
            title="Tutup"
            variant="primary"
            @click="back()"
          >
            <feather-icon
              icon="XIcon"
              size="12" />
          </b-button> -->
          <b-button
            v-ripple.400="'rgba(113, 102, 240, 0.15)'"
            title="Muat Ulang"
            variant="primary"
            @click="refreshGrid()"
          >
            <feather-icon icon="RefreshCwIcon" size="12" />
          </b-button>
        </b-button-group>
      </div>
      <div class="col-xs-12 col-sm-12 col-md-4 col-lg-3">
        <h4 class="page-title mb-0 float-right mr-2 mt-1">
          <feather-icon icon="DatabaseIcon" size="20" />
          Audit
          <small class="text-primary">{{ title }}</small>
        </h4>
      </div>
    </div>
    
    <dx-data-grid
      :ref="name"
      :element-attr="{ class: 'shadow-datagrid rounded-lg' }"
      :show-borders="false"
      :data-source="dataSource"
      :column-auto-width="true"
      :word-wrap-enabled="true"
      :show-column-lines="true"
      :show-row-lines="true"
      :row-alternation-enabled="true"
      :repaint-changes-only="true"
      :focused-row-enabled="false"
      :focus-state-enabled="true"
      :filter-sync-enabled="true"
      :filter-value="null"
      :on-content-ready="gridAuditContentReady"
    >
      <dx-filter-row :visible="false" />
      <dx-column-chooser mode="select" />
      <dx-pager
        :visible="true"
        :show-page-size-selector="true"
        :show-info="true"
        :show-navigation-buttons="true"
      />
      <dx-paging :page-size="20" />
      <dx-sorting mode="none" />

      <div slot="eventTpl" slot-scope="data">
        <b-badge
          class="text-white text-uppercase"
          :variant="statusType[data.data.value]"
        >
          {{ data.data.value }}
        </b-badge>
      </div>
      <div slot="attributesTpl" slot-scope="data">
        <li
          v-for="(item, index) in data.data.value"
          v-if="!exclude_index.includes(index)"
        >
          {{ index }} :
          <div v-if="typeof item == 'object' && item !== null">
            <ul>
              <li v-for="(val, key) in item">
                {{ key }} : 

                <div v-if="typeof val == 'object' && val !== null">
                  <ul>

                    <li v-for="(point, pointer) in val">
                      {{ pointer }} :
                      <div v-if="typeof point == 'object' && point !== null">
                        <ul>

                          <li v-for="(isi, arr) in point">
                            {{ arr }} :{{ isi }}</li>
                            </ul>
                      </div>
                      <span v-else>{{ point }}</span>

                    </li>

                  </ul>
                </div>

                <span v-else>{{ val }}</span>
              </li>
            </ul>
          </div>

          <span v-else>{{ item }}</span>
        </li>
      </div>
      <div slot="oldTpl" slot-scope="data">
        <li
          v-for="(item, index) in data.data.value"
          v-if="!exclude_index.includes(index)"
        >
          {{ index }} : {{ item }}
        </li>
      </div>
    </dx-data-grid>
  </div>
</template>

<script>
import { nextTick } from "@vue/composition-api";

export default {
  name: "AuditGrid",
  components: {},
  props: {
    gridHeight: {
      type: Number,
      default: function _default() {
        return window.innerHeight - 142;
      },
    },
    gridKey: {
      type: String,
      default: "id",
    },
  },
  data() {
    return {
      title: "History Data Audit",
      name: "audit",
      dataGrid: null,
      dataSource: null,
      route: null,
      dateHistory:null,
      statusType: {
        created: "success",
        updated: "warning",
        deleted: "danger",
        restored: "primary",
      },
      exclude_index: [
        "created_at",
        "updated_at",
        "updated_by",
        "created_by",
        "deleted_at",
        "deleted_by",
        "_id",
        "id"
      ],
      dataSourceLookup: {
        store: {
          type: "array",
          data: [
            { id: "created", name: "created" },
            { id: "updated", name: "updated" },
            { id: "deleted", name: "deleted" },
            { id: "restored", name: "restored" },
          ],
          key: "id",
        },
        pageSize: 10,
        paginate: true,
      },
    };
  },
  created() {},
  mounted() {
    this.renderGrid();
  },
  methods: {
    dateContext(ctx) {
        this.dateHistory = ctx.selectedYMD
    },

    gridAuditContentReady(e) {
      const vm = this;
      if (e.component.shouldSkipNextReady) {
        e.component.shouldSkipNextReady = false;

        e.component.columnOption("id", {
          visible: false,
        });

        e.component.columnOption("_id", {
          visible: false,
        });
        e.component.columnOption("subject_type", {
          visible: false,
        });
        e.component.columnOption("attributes", {
          caption: "New Value",
          cellTemplate: "attributesTpl",
          width: 450,
        });

        e.component.columnOption("old", {
          caption: "Old Value",
          cellTemplate: "oldTpl",
          width: 450,
        });

        e.component.columnOption("description", {
          caption: "Aksi",
          cellTemplate: "eventTpl",
          width: 70,
        });

        e.component.columnOption("log_name", {
          caption: "User",
          width: 150,
        });

        e.component.columnOption("created_by", {
          visible: false,
        });

        e.component.columnOption("updated_by", {
          visible: false,
        });

        e.component.columnOption("deleted_by", {
          visible: false,
        });

        e.component.columnOption("updated_at", {
          visible: false,
        });

        e.component.columnOption("deleted_at", {
          visible: false,
        });

        e.component.columnOption("created_at", {
          width: 145,
          caption: "Tanggal",
          format: "d/M/yyyy",
          displayFormat: "DD MMM YYYY, HH:mm:ss",
          dataType: "date",
          dataField: "created_at",
          calculateCellValue(data) {
            return vm.calculateDateCellValue(
              data.created_at,
              null,
              "DD MMM YYYY, HH:mm:ss"
            );
          },
        });
      } else {
        e.component.shouldSkipNextReady = true;
      }
    },
    back() {
      // if (this.dataGrid != null) {
      //   this.dataGrid.dispose()
      // }
      // this.$events.fire(this.hideEvent)
    },
    refreshGrid() {
      this.dataGrid.refresh();
    },
    updateRoute(data) {
      this.route = data;
      const vm = this;
      nextTick().then(() => {
        vm.renderGrid();
      });
    },

    onchangeDate(){
      const vm = this;

      this.dataSource = vm.$aspnet.createStore({
        key: "id",
        loadUrl: `api/allAuditLog/`,
        loadParams: {
          date: this.dateHistory
        },
        onBeforeSend(operation, ajaxSettings) {
          if (operation === "load") {
            ajaxSettings.headers = {
              Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
            };
            ajaxSettings.data = {
              data: JSON.stringify(ajaxSettings.data),
            };
          }
        },
      });

    },
    renderGrid() {
      const vm = this;

      if (this.dataGrid != null) {
        this.dataGrid.dispose();
      }

      this.dataSource = vm.$aspnet.createStore({
        key: "id",
        loadUrl: `api/allAuditLog/`,
        loadParams: {
          date: this.dateHistory
        },
        onBeforeSend(operation, ajaxSettings) {
          if (operation === "load") {
            ajaxSettings.headers = {
              Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
            };
            ajaxSettings.data = {
              data: JSON.stringify(ajaxSettings.data),
            };
          }
        },
      });

      this.dataGrid = this.$refs[this.name].instance;
    },
  },
  events: {
    // eslint-disable-next-line no-unused-vars
    RepaintAuditGridEvent(data) {
      this.renderGrid();
    },
  },
};
</script>
